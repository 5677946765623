jQuery(document).ready(function () {

	$('.fooldal-slider .owl-carousel').owlCarousel({
		items: 1,
		loop: true,
		autoplay: true,
		autoplayTimeout: $('.fooldal-slider .owl-carousel').data('timeout'),
		autoplaySpeed: 2000,
	});

	var telephelyPopupClose = function (e) {
		if (!$(e.target).parents('.terkep-telephely').length) {
			$('.terkep-telephely.active').removeClass('active');
			$(document).off('click', telephelyPopupClose);
		}
	};

	$(".terkep-telephely").on("click", ".terkep-telephely-bullet, .terkep-telephely-nev", function (e) {
		e.stopImmediatePropagation();
		var $telephely = $(this).parents(".terkep-telephely");
		$telephely.siblings(".terkep-telephely").removeClass("active");
		$telephely.toggleClass("active");

		if ($telephely.hasClass("active")) {
			$(document).on('click', telephelyPopupClose);
		}

		//relativ popover
		var elementOffset = e.pageY; //elem poszicíó az oldal tetejéhez képest
		var documentOffset = $(document).scrollTop(); //ablak teteje az oldal tetejéhez képest
		var currentOffset = elementOffset - documentOffset; //elem pozicíó az ablak tetejéhez képest
		var windowHeight = $(window).height(); //ablak magassága

		if(currentOffset <= windowHeight/2){ //lent jelenik meg
			$('.terkep-telephely-popover').css('bottom', 'auto');
			$('.terkep-telephely-popover').css('top', 'calc(100% + 30px)');
			$('.terkep-telephely-popover').removeClass('up');
			$('.terkep-telephely-popover').addClass('down');
		}
		if(currentOffset >= windowHeight/2){ //fent jelenik meg
			$('.terkep-telephely-popover').css('bottom', 'calc(100% + 30px)');
			$('.terkep-telephely-popover').css('top', 'auto');
			$('.terkep-telephely-popover').removeClass('down');
			$('.terkep-telephely-popover').addClass('up');
		}
	});

	

	
	

	//ajánlatkérő
	$(".fomenupont-ajanlatkero").each(function(){
		$($(this).data("target")).find('.modal-content').load($(this).attr('href'));
	});

	$('a.level').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});

	$('a[href^="mailto:"]').each(function (i, o) {
		
		if($(o).html().includes("kukac")){
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
		}
	});

	$('a[href^="javascript:void(location.href="]').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});

	$('a.level').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});

	$(".header-menusav-toggle").on("click", function (e) {
		e.preventDefault();
		if (!$(e.target).is(":visible")) {
			return;
		}

		$(".header-menusav").toggleClass("open");
	});

	$('.dropdown .active').parents('.dropdown').addClass('active');

	$('.terkep-telephely-xs-lista:visible').on('click', '.terkep-telephely-xs-nev', function (e) {
		$(this).next().stop().slideToggle(250);
	});

	new WOW({
		mobile: true
	}).init();

	//	//-------ajánlatkérő form---------
	//	function ajanlatkero_tipus() {
	//		var $this = $(this),
	//			value = $this.val();
	//
	//		if (value == 'szallitmanyozas') {
	//			$('.szallitmanyozas').attr('required', 'required').prop('required', 'required').parents('.form-group').slideDown();
	//		} else {
	//			$('.szallitmanyozas').removeAttr('required').removeProp('required').parents('.form-group').slideUp();
	//		}
	//
	//		if (value == 'raktarozas') {
	//			$('.raktarozas').attr('required', 'required').prop('required', 'required').parents('.form-group').slideDown();
	//		} else {
	//			$('.raktarozas').removeAttr('required').removeProp('required').parents('.form-group').slideUp();
	//		}
	//
	//		if (value == 'vam') {
	//			$('.vam').attr('required', 'required').prop('required', 'required').parents('.form-group').slideDown();
	//		} else {
	//			$('.vam').removeAttr('required').removeProp('required').parents('.form-group').slideUp();
	//		}
	//		if (value == 'egyeb') {
	//			$('.egyeb').attr('required', 'required').prop('required', 'required').parents('.form-group').slideDown();
	//		} else {
	//			$('.egyeb').removeAttr('required').removeProp('required').parents('.form-group').slideUp();
	//		}
	//	}
	//
	//	$('.ajanlatkero_tipus').each(ajanlatkero_tipus);
	//	$('.ajanlatkero_tipus').on('change', ajanlatkero_tipus);
	//
	//	function ajanlatkero_egyeb() {
	//		var $this = $(this),
	//			value = $this.val();
	//
	//		if (value == 'egyeb') {
	//			$('.egyeb_egyeb').attr('required', 'required').prop('required', 'required').parents('.form-group').slideDown();
	//		} else {
	//			$('.egyeb_egyeb').removeAttr('required').removeProp('required').parents('.form-group').slideUp();
	//		}
	//	}
	//
	//	$('.ajanlatkero_egyeb').each(ajanlatkero_egyeb);
	//	$('.ajanlatkero_egyeb').on('change', ajanlatkero_egyeb);
	//
	//	$('.ajanlatkero_tipus').each(ajanlatkero_tipus);
	//	$('.ajanlatkero_tipus').on('change', ajanlatkero_tipus);
	//
	//	function ajanlatkero_koltsegviselo() {
	//		var $this = $(this),
	//			value = $this.val();
	//
	//		if (value == 'egyeb') {
	//			$('.koltsegviselo_nev').attr('required', 'required').prop('required', 'required').parents('.form-group').slideDown();
	//		} else {
	//			$('.koltsegviselo_nev').removeAttr('required').removeProp('required').parents('.form-group').slideUp();
	//		}
	//	}
	//
	//	$('.ajanlatkero_koltsegviselo').each(ajanlatkero_koltsegviselo);
	//	$('.ajanlatkero_koltsegviselo').on('change', ajanlatkero_koltsegviselo);

	});

function submitAjanlatkeroForm(token) {
	document.getElementById('ajanlatkeroForm').submit();
}

function submitSajtolistaForm(token) {
	document.getElementById('sajtolistaForm').submit();
}

$(".fancybox").fancybox({
	beforeShow: function () {
		var alt = this.element.find('img').attr('alt');

		this.inner.find('img').attr('alt', alt);

		this.title = alt;
	}
});

$(window).load(function () {
	if (document.location.hash.length) {
		var pozicioModal = $(document.location.hash);

		if (pozicioModal.length && pozicioModal.hasClass('modal')) {
			pozicioModal.modal('show');
		}
	}
});

/*
//sticky aside
window.onscroll = function(){asidePos()};

var aside_sav = document.getElementById("aside_sav");
var footer = document.getElementById("footer");
var sticky = aside_sav.offsetTop;

function asidePos(){
	if(window.pageYOffset >= sticky){
	  var page_w = $(window).width();
	  var doc_h = $(document).height();
	  var content_w = $(".container-flex").width();
	  var margin_w = (page_w - content_w) / 2;
	  var cikk_w = $(".aloldal-cikk").width();
	  var footer_p = footer.offsetTop;
	  var footer_h = doc_h - footer_p;

		aside_sav.classList.add("sticky");
		$("#aside_sav").css("left", margin_w + cikk_w);
		if(window.pageYOffset >= footer_p - 1000){
			$("#aside_sav").css("bottom", footer_h);
		}
		else{
			$("#aside_sav").css("bottom", "unset");
		}
	}else{
		aside_sav.classList.remove("sticky");
	}
}
*/
/*
$(window).scroll(function(){
	var aside = document.getElementById("aside_sav");
	var elementOffset = aside.offsetTop; //elem poszicíó az oldal tetejéhez képest
	var documentOffset = $(document).scrollTop(); //ablak teteje az oldal tetejéhez képest
	var currentOffset = elementOffset - documentOffset; //elem pozicíó az ablak tetejéhez képest

	console.log(elementOffset);
	console.log(documentOffset);
	console.log(currentOffset);

	

	if(currentOffset <= 10 && elementOffset >= 500){
		$(".aloldal-oldalsav").css("position", "fixed");
		$(".aloldal-oldalsav").css("top", "1rem");
		$(".aloldal-oldalsav").css("right", "31rem");
	}

	else{
		$(".aloldal-oldalsav").css("position", "relative");
		$(".aloldal-oldalsav").css("top", "0");
		$(".aloldal-oldalsav").css("right", "0");
	}
	
	
  });
  */
